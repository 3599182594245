export const state = {
    messages: [],
};

export const getters = {
    messages: s => s.messages,
};

export const actions = {
    // eslint-disable-next-line
    addError({ commit }, error) {
        const number = Math.random();
        number.toString(36);
        const id = number.toString(36).substr(2, 9);
        commit('addMessage', { value: error, isError: true, id });
    },
    addMessage({ commit }, message) {
        const number = Math.random();
        number.toString(36);
        const id = number.toString(36).substr(2, 9);
        commit('addMessage', { value: message.message, id });
        window.setTimeout(() => {
            commit('dismissMessage', id);
        }, 7000);
    },
    dismissMessage({ commit }, messageId) {
        commit('dismissMessage', messageId);
    },
};

export const mutations = {
    addMessage(s, message) {
        s.messages.push(message);
    },
    dismissMessage(s, messageId) {
        for (let i = 0; i < s.messages.length; i += 1) {
            if (s.messages[i].id === messageId) {
                s.messages.splice(i, 1);
                return;
            }
        }
    },
};


export default {
    state,
    getters,
    actions,
    mutations,
};
