export const state = {
  showAside: true
}

// getters
export const getters = {
  showAside: s => s.showAside,
}

// actions
export const actions = {
  ToggleAside: ({ commit }) => commit('Show_Aside'),
  HideAside: ({ commit }) => commit('Hide_Aside'),
}


// mutations
export const mutations = {
  Show_Aside(s) {
    s.showAside = !s.showAside
    localStorage.setItem('isSubMenuVisible', s.showAside.value)
  },
  Hide_Aside(s) {
    s.showAside = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}