import api from '@/lib/services/general.service'

export const state = {
  show: false,
  content: '',
  id: null,
  title: '',
  contact: null,
  timeline: null,
  timelines: [],
  isLoading: true,
}

export const getters = {
  show: x => x.show,
  content: x => x.content,
  id: x => x.id,
  title: x => x.title,
  contact: x => x.contact,
  timeline: x => x.timeline,
  timelines: x => x.timelines,
  isLoading: x => x.isLoading
}

export const actions = {
  async showSidePanel({ commit },) {
    commit("SHOW_SIDE_PANEL")
  },
  async fetchTimeline({ commit }, { id, content, title }) {
    commit("SET_ID", id)
    commit("SET_CONTENT", content)
    commit("SET_TITLE", title)
    return await api.getById(`timeline`, id, '').then(response => {
      if (response) {
        commit("SET_TIMELINE", response.data)
      }
      return false;
    });
  },
  async fetchTimelines({ commit }, { id, content, title, contact }) {
    commit("SET_ID", id)
    commit("SET_CONTENT", content)
    commit("SET_TITLE", title)
    commit("SET_CONTACT", contact)
    return await api.getWithPathById(`timeline`, id, 'all').then(response => {
      if (response) {
        commit("SET_SIDE_PANEL_DATA", response.data)
      }
      return false;
    });
  },
  hideSidePanel({ commit }) {
    commit("HIDE_SIDE_PANEL")
  },
}

export const mutations = {
  SHOW_SIDE_PANEL(x) {
    x.show = true
  },
  SET_CONTENT(x, content) {
    x.content = content
  },
  SET_ID(x, id) {
    x.id = id
  },
  SET_TITLE(x, title) {
    x.title = title
  },
  SET_CONTACT(x, contact) {
    x.contact = contact
  },
  SET_TIMELINE(x, timeline) {
    x.isLoading = false
    x.timeline = timeline
  },
  SET_SIDE_PANEL_DATA(x, timelines) {
    x.isLoading = false
    x.timelines = timelines
  },
  HIDE_SIDE_PANEL(x) {
    x.show = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}