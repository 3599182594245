// calndr-helper.js

export const getMonthName = (year, month) => {
    return (new Date(year, month, 1)).toLocaleString('default', { month: 'long' })
}

export const pad = (n, width, z) => {
    z = z || '0'
    n = n + ''
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export const timeStringToFloat = (time) => {
    var hoursMinutes = time.split(/[.:]/)
    var hours = parseInt(hoursMinutes[0], 10)
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0
    return hours + minutes / 60
}

export const ToFloatTimeString = (dateTime) => {
    let hours = dateTime.getHours()
    let minutes = dateTime.getMinutes()
    return hours + minutes / 60
}

export const toAmPmTimeString = (dateTime) => {
    let ap = ''
    let h = dateTime.getHours()

    if (h < 12) {
        ap = 'AM'
    } else {
        ap = 'PM'
        h -= 12
    }
    return `${pad(h, 2)}:${pad(dateTime.getMinutes(), 2)} ${ap}`
}

export const toTimeString = (dateTime) => {
    return `${pad(dateTime.getHours(), 2)}:${pad(dateTime.getMinutes(), 2)}`
}

export const getTimeLength = (stDate, enDate) => {
    let t2 = stDate.getTime()
    let t1 = enDate.getTime()
    if (t1 > t2) {
        let t3 = t1
        t1 = t2
        t2 = t3
    }
    return (t2 - t1) / (1000 * 3600)
}