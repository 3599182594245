import axios from 'axios';
const api = `${process.env.VUE_APP_API_ADDRESS}/v1`


export default {
  async get(service, request) {
    const queryString = Object.keys(request).map(key => `${key}=${request[key]}`).join('&');
    return await axios.get(`${api}/${service}?${queryString}`)
  },
  async getByDifferentVersion(service, version) {
    let diffApi = api.split('v1')[0] + version;
    return await axios.get(`${diffApi}/${service}`);
  },
  async insertWitDifferentVersion(service, model, version, config = {}) {
    let diffApi = api.split('v1')[0] + version;
    return await axios.post(`${diffApi}/${service}`, model, config);
  },
  async getWithUrl(url, service, request, config) {
    const queryString = Object.keys(request).map(key => `${key}=${request[key]}`).join('&');
    return await axios.get(`${url}/${service}?${queryString}`, config)
  },
  async getById(service, id,) {
    return await axios.get(`${api}/${service}/${id}`)
  },
  async insert(service, model) {
    return await axios.post(`${api}/${service}`, model)
  },
  async getEmailBody(service, model) {
    return await axios.post(`${api}/${service}`, model)
  },
  async deleteWithRequest(service, model) {
    return await axios.post(`${api}/${service}`, model)
  },
  async toggle(service,) {
    return await axios.post(`${api}/${service}`)
  },
  async update(service, id, model) {
    return await axios.put(`${api}/${service}/${id}`, model)
  },
  async updateWithoutID(service, model) {
    return await axios.put(`${api}/${service}`, model)
  },
  async patch(service, id, patchModel) {
    return await axios.patch(`${api}/${service}/${id}`, patchModel)
  },
  async delete(service, id) {
    return await axios.delete(`${api}/${service}/${id}`)
  },
  async deleteWithoutId(service) {
    return await axios.delete(`${api}/${service}`)
  },
  async getWithPath(service, path, request) {
    const queryString = Object.keys(request).map(key => `${key}=${request[key]}`).join('&');
    return await axios.get(`${api}/${service}/${path}?${queryString}`)
  },
  async getWithPathById(service, id, path) {
    return await axios.get(`${api}/${service}/${id}/${path}`)
  },
  async getWithPathByIdDownload(service, id, path) {
    return await axios.get(`${api}/${service}/${id}/${path}`, {responseType: 'arraybuffer'})
  },
  async downloadByUrl(path) {
    return await axios.get(`${api}/${path}`, {responseType: 'arraybuffer'})
  },
  async insertWithPath(service, path, model) {
    return await axios.post(`${api}/${service}/${path}`, model)
  },
  async updateWithPath(service, id, path, model) {
    return await axios.put(`${api}/${service}/${id}/${path}`, model)
  },
  async patchWithPath(service, id, path, model) {
    const patchModel = model.map(c => { return { "op": "replace", "path": `/${c.key}`, "value": c.val }; })
    return await axios.patch(`${api}/${service}/${id}/${path}`, patchModel)
  },
  async patchWithPathNoMapping(service, id, path, model) {
    return await axios.patch(`${api}/${service}/${id}/${path}`, model);
  },
  async deleteWithPath(service, id, path) {
    return await axios.delete(`${api}/${service}/${id}/${path}`)
  },

  uploadWithoutId(service,  formData) {
    return axios.post(`${api}/${service}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  upload(service, id,  formData) {
    return axios.post(`${api}/${service}/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  uploadWithPath(service, id, path, formData) {
    return axios.post(`${api}/${service}/${id}/${path}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
}
