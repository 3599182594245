import api from '@/lib/services/general.service'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { ToFloatTimeString, toAmPmTimeString, toTimeString, getTimeLength } from "@/components/common/vue-calendar/functions/calndr-helper"

const $toast = useToast();
const initialState = (important = false) => ({
  events: [],
  requestData: {
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    start: new Date().toISOString(),
    view: 4,
    important,
  },
  isLoading: true,
  dataLoaded: new Set(),
});
export const state = initialState();
export const getters = {
  events: s => s.events,
  importantEvents: (state) => {
    let items = state.events;
    return items
      .filter(e => e.isImportant)
      .sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));
  },
  important: s => s.requestData.important,
  isLoading: s => s.isLoading,
  dataLoaded: s => s.dataLoaded,

};
export const actions = {
  async fetchEventsAndCounts({ commit, dispatch, state }, newRequestData) {
    const important = state.requestData.important;
    commit('RESET_STATE', important);
    await Promise.all([
      dispatch('fetchEvents', newRequestData),
    ]);
  },

  async fetchEvents({ commit, state }, newRequestData) {
    let requestData = null;
    if (newRequestData === null) {
      requestData = {
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        start: new Date().toISOString(),
        view: 4,
        important: state.requestData.important,
      }
    } else {
      requestData = newRequestData;
    }
    const currentRequestData = state.requestData;
    const dataKey = `${requestData.start}-${requestData.view}-${requestData.important}`;
    if (newRequestData !== null && state.dataLoaded.has(dataKey)) {
      commit('SET_REQUEST_DATA', { data: requestData, dataKey: null });
      return;
    }
    if (newRequestData === null || JSON.stringify(requestData) !== JSON.stringify(currentRequestData)) {
      commit('SET_REQUEST_DATA', { data: requestData, dataKey });
      try {
        await fetchEventsWithRetry(commit, requestData, 0);
      } finally {
        commit('SET_LOADING', false);
      }
    }
  },
  async addEvent({ commit, dispatch }, event) {
    try {
      let response = await api.insert('event', event);
      if (response) {
        if (event.timelineId) {
          dispatch('timeline/addEventIntoTimeline', response.data, { root: true });
        }
        commit("ADD_EVENT", response.data.data);
        $toast.open({
          message: 'Event Added',
          type: 'success',
          duration: 3000,
          dismissible: true
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error Adding Event:', error);
      return false;
    }
  },
  async markAsImportant({ commit, dispatch }, request) {
    try {
      const response = await api.insert('event/markAsImportant', request);
      if (response) {
        commit("UPDATE_EVENTS", response.data);
        $toast.open({
          message: 'Event Updated',
          type: 'info',
          duration: 3000,
          dismissible: true
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error marking event as important:', error);
      return false;
    }
  },
  async markAsNotImportant({ commit, dispatch }, request) {
    try {
      const response = await api.insert('event/markAsNotImportant', request);
      if (response) {
        commit("UPDATE_EVENTS", response.data);
        $toast.open({
          message: 'event Updated',
          type: 'info',
          duration: 3000,
          dismissible: true
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error marking event as not important:', error);
      return false;
    }
  },
  async deleteEvent({ commit, dispatch, state }, request) {
    try {
      const response = await api.deleteWithRequest('event/delete', request);
      if (response.data) {
        $toast.open({
          message: 'Event Deleted',
          type: 'warning',
          duration: 3000,
          dismissible: true
        });
        commit("REMOVE_EVENTS", request.ids);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error deleting note:', error);
      return false;
    }
  },
  async editEvent({ commit }, { id, request }) {
    try {
      const response = await api.patch('event', id, request);
      if (response) {
        commit("UPDATE_EVENTS", response.data);
        $toast.open({
          message: 'Event Updated',
          type: 'warning',
          duration: 3000,
          dismissible: true
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error editing Event:', error);
      return false;
    }
  },
  loadImportant({ commit, dispatch, state }, important) {
    if (state.requestData.important !== important) {
      let request = {
        timeZoneId: state.requestData.timeZoneId,
        start: state.requestData.start,
        view: state.requestData.view,
        important: important,
      }
      dispatch('fetchEvents', request);
    }
  }
}
async function fetchEventsWithRetry(commit, requestData, retryCount) {
  try {
    let response = null;
    let requestParams = { ...requestData };
    if (requestParams.important === false) {
      delete requestParams.important;
    }
    response = await api.get('event', requestParams);
    if (response) {
      commit("SET_EVENTS", response.data.data);
    }
  } catch (error) {
    console.error('Error fetching events:', error);
    if (retryCount < 3) {
      await new Promise(resolve => setTimeout(resolve, 10000));
      await fetchEventsWithRetry(commit, requestData, retryCount + 1);
    } else {
      console.error('Max retries reached. Failed to fetch events.');
    }
  }
}
export const mutations = {
  RESET_STATE(state, important) {
    Object.assign(state, initialState(important));
  },
  SET_LOADING(s, isLoading) {
    s.isLoading = isLoading;
  },
  SET_REQUEST_DATA(s, { data, dataKey }) {
    s.requestData.timeZoneId = data.timeZoneId;
    s.requestData.start = data.start;
    s.requestData.view = data.view;
    s.requestData.important = data.important
    if (dataKey !== null) {
      s.dataLoaded.add(dataKey);
    }
  },
  SET_EVENTS(s, events) {
    events.forEach(e => {
      try {
        let stDate = new Date(e.start)
        let enDate = new Date(e.end)
        let dNoTime = new Date(e.start)
        dNoTime.setHours(0, 0, 0, 0)
        const existingEventIndex = s.events.findIndex(existingEvent => existingEvent.id === e.id);
        const eventData = {
          id: e.id,
          summary: e.summary,
          description: e.description,
          time: toAmPmTimeString(stDate),
          time_long: toTimeString(stDate),
          end_time: toAmPmTimeString(enDate),
          end_time_long: toTimeString(enDate),
          start_time_dec: ToFloatTimeString(stDate),
          length: getTimeLength(stDate, enDate),
          eventType: e.eventType,
          dateNoTime: dNoTime,
          startDatetime: stDate,
          endDatetime: enDate,
          day: dNoTime.getDay(),
          href: "#",
          colour: "blue",
          isImportant: e.isImportant,
          isAllDay: e.isAllDay,
        };
        if (existingEventIndex === -1) {
          s.events.push(eventData);
        } else {
          s.events[existingEventIndex] = { ...s.events[existingEventIndex], ...eventData };
        }
      }
      catch (error) {
        console.error(error)
      }
    })
    s.isLoading = false
  },
  UPDATE_EVENTS(s, responses) {
    if (Array.isArray(responses)) {
      console.log("aara")
      // Handle case where responses is an array
      responses.forEach(response => {
        const event = s.events.find(event => event.id === response.id);
        if (event) {
          let stDate = new Date(response.startDateTime)
          let enDate = new Date(response.endDateTime)
          let dNoTime = new Date(response.startDateTime)
          dNoTime.setHours(0, 0, 0, 0)
          event.time = toAmPmTimeString(stDate),
            event.time_long = toTimeString(stDate),
            event.end_time = toAmPmTimeString(enDate),
            event.end_time_long = toTimeString(enDate),
            event.start_time_dec = ToFloatTimeString(stDate),
            event.length = getTimeLength(stDate, enDate),
            event.dateNoTime = dNoTime,
            event.startDatetime = stDate,
            event.endDatetime = enDate,
            event.day = dNoTime.getDay(),
            event.isImportant = response.isImportant;
          event.isAllDay = response.isAllDay;
          event.summary = response.summary;
          event.description = response.description;
          event.eventType = response.eventType;
        }
      });
    } else {
      // Handle case where responses is a single object
      let stDate = new Date(responses.startDateTime)
      let enDate = new Date(responses.endDateTime)
      let dNoTime = new Date(responses.startDateTime)
      dNoTime.setHours(0, 0, 0, 0)
      const event = s.events.find(event => event.id === responses.id);
      if (event) {
        event.time = toAmPmTimeString(stDate),
          event.time_long = toTimeString(stDate),
          event.end_time = toAmPmTimeString(enDate),
          event.end_time_long = toTimeString(enDate),
          event.start_time_dec = ToFloatTimeString(stDate),
          event.length = getTimeLength(stDate, enDate),
          event.dateNoTime = dNoTime,
          event.startDatetime = stDate,
          event.endDatetime = enDate,
          event.day = dNoTime.getDay(),
          event.isImportant = responses.isImportant;
        event.isAllDay = responses.isAllDay;
        event.summary = responses.summary;
        event.description = responses.description;
        event.eventType = responses.eventType;
      }
    }
  },
  REMOVE_EVENTS(s, ids) {
    s.events = s.events.filter(item => !ids.includes(item.id));
  },
  ADD_EVENT(s, event) {
    event.forEach(e => {
      try {
        let stDate = new Date(e.start)
        let enDate = new Date(e.end)
        let dNoTime = new Date(e.start)
        dNoTime.setHours(0, 0, 0, 0)
        const eventData = {
          id: e.id,
          summary: e.summary,
          description: e.description,
          time: toAmPmTimeString(stDate),
          time_long: toTimeString(stDate),
          end_time: toAmPmTimeString(enDate),
          end_time_long: toTimeString(enDate),
          start_time_dec: ToFloatTimeString(stDate),
          length: getTimeLength(stDate, enDate),
          eventType: e.eventType,
          dateNoTime: dNoTime,
          startDatetime: stDate,
          endDatetime: enDate,
          day: dNoTime.getDay(),
          href: "#",
          colour: "blue",
          isImportant: e.isImportant,
          isAllDay: e.isAllDay
        };
        s.events.push(eventData);
      }
      catch (error) {
        console.error(error)
      }
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};