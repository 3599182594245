export const state = {
    loadings: [],
};

export const getters = {
    loading: s => id => {
        let r=  s.loadings.find(i=>i.id === id);
        return r ?  r.loading : false;
    },
};

export const actions = {
    setLoading ({ commit }, {id, loading}) {
        commit('setLoading', {id, loading});
    },
};

export const mutations = {
    setLoading (s, {id, loading}) {
        let r=  s.loadings.find(i=>i.id === id);
        if (r) {
            r.loading = loading;
        } else  {
        s.loadings.push({id, loading});
    }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
