import axios from 'axios';
import store from '@/lib/store';

axios.interceptors.request.use((config) => {
      const authToken = store.getters["user/access_token"];
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },

    error => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        window.localStorage.clear();
        window.location.href = '/';
      }
      return Promise.reject(error);
    },
);
