import api from '@/lib/services/general.service'
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

const $toast = useToast()

export const state = {
  tasks: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  isLoading: true,
  taskCounts: {
    completedCount: 0,
    importantCount: 0,
    monthCount: 0,
    somedayCount: 0,
    todayCount: 0,
    totalCount: 0,
    waitingOnCount: 0,
    weekCount: 0,
  },
}
export const getters = {
  tasks: s => s.tasks,

  importantTasks: (state) => {
    let items = state.tasks.items;
    return items
      .filter(task => task.isImportant && task.status !== 'done')
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  waitingOnTasks: (state) => {
    let items = state.tasks.items;
    return items
      .filter(task => task.status === "waitingon")
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  doneTasks: (state) => {
    let items = state.tasks.items;
    return items
      .filter(task => task.status === "done")
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  somedayTasks: (state) => {
    let items = state.tasks.items;
    return items
      .filter(task => task.status === "someday")
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  unscheduledTasks: (state) => {
    let items = state.tasks.items;
    return items
      .filter(task => task.dueDate == null && task.status !== 'someday' && task.status !== 'done')
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  todayTasks: (state) => {
    const currentDate = new Date();
    let items = state.tasks.items;
    return items
    .filter(task => {
      const taskDueDate = new Date(task.dueDate);
        return taskDueDate.toDateString() === currentDate.toDateString() &&
          taskDueDate >= currentDate && task.status !== 'done'
      })
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  tasksWithDate: (state) => {
    const currentDate = new Date();
    const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    let items = state.tasks.items;
    return items
      .filter(task => {
        const taskDueDate = new Date(task.dueDate);
        let taskDueDateOnly = new Date(taskDueDate.getFullYear(), taskDueDate.getMonth(), taskDueDate.getDate());
        return taskDueDateOnly >= currentDateOnly && task.status !== 'done';
      })
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  overdueTasks: (state) => {
    let items = state.tasks.items;
    const currentDate = new Date();
    return items
      .filter(task => {
        const taskDueDate = new Date(task.dueDate);
        // return task.dueDate != null && task.status !== 'done' && task.status !== 'someday' && task.status !== 'waitingon' && taskDueDate < currentDate;
        return task.dueDate != null && task.status !== 'someday' && task.status !== 'done' && taskDueDate < currentDate;
      })
      .sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));
  },
  nextWeekTasks: (state) => {
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const next7DaysEndDate = new Date(currentDate.getTime() + oneWeek);
    let items = state.tasks.items;
    return items
    .filter(task => {
      const taskDueDate = new Date(task.dueDate);
      return taskDueDate <= next7DaysEndDate &&
          taskDueDate > currentDate && task.status !== 'done';
      })
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  nextMonthTasks: (state) => {
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneMonth = 30 * oneDay;
    //const oneWeek = 7 * oneDay;
    //const next7DaysEndDate = new Date(currentDate.getTime() + oneWeek);
    const next30DaysEndDate = new Date(currentDate.getTime() + oneMonth);
    let items = state.tasks.items;
    return items
      .filter(task => {
        const taskDueDate = new Date(task.dueDate);
        return taskDueDate <= next30DaysEndDate && taskDueDate > currentDate && task.status !== 'done';
      })
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  },
  isLoading: s => s.isLoading,
  taskItems: s => s.taskItems,
  taskCounts: s => s.taskCounts
};
export const actions = {
  async fetchTasks({ commit, dispatch }, requestData) {
    return await api.get(`task`, requestData).then(response => {
      if (response) {
        const items = response.data.items.map(item => {
          if (item.dueDate != null) {
            const dueDate = new Date(item.dueDate)
            const localDueDate = new Date(dueDate.getTime() - (dueDate.getTimezoneOffset() * 60000))
            item.dueDate = localDueDate.toISOString()
          }
          if (item.remindDate != null) {
            const remindDate = new Date(item.remindDate)
            const localRemindDate = new Date(remindDate.getTime() - (remindDate.getTimezoneOffset() * 60000))
            item.remindDate = localRemindDate.toISOString()
          }
          return item;
        })

        response.data.items = items
        commit("SET_TASKS", response.data)
        dispatch('fetchTaskCount');
      }
      return true;
    });
  },
  async addTask({ commit, dispatch }, task) {
    return await api.insert(`task`, task).then(response => {
      if (response) {
        if (task.timelineId) {
          dispatch('timeline/addTaskIntoTimeline', response.data, { root: true })
        }
        else {
          commit("ADD_TASK", response.data);
        }

        $toast.open({
          message: 'Task Added',
          type: 'success',
          duration: 3000,
          dismissible: true
        })
        dispatch('fetchTaskCount');
        return true;
      }
      return false;
    });
  },
  async fetchTaskCount({ commit }) {
    return await api.get(`task/count`, {}).then(response => {
      if (response) {
        commit("SET_COUNT", response.data);
      }
      return true;
    });
  },
  async updateStatus({ commit, dispatch }, request) {
    return api.insert(`task/updateStatus`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_TASK", response.data);
          $toast.open({
            message: 'Task Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTaskCount');
          return true;
        }
        return false;
      });
  },
  async markAsImportant({ commit, dispatch }, request) {
    return api.insert(`task/markAsImportant`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_TASK", response.data);
          $toast.open({
            message: 'Task Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTaskCount');
          return true;
        }
        return false;
      });
  },
  async markAsNotImportant({ commit, dispatch }, request) {
    return api.insert(`task/markAsNotImportant`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_TASK", response.data);
          $toast.open({
            message: 'Task Updated',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTaskCount');
          return true;
        }
        return false;
      });
  },
  async deleteTask({ commit, dispatch }, request) {
    return await api.deleteWithRequest(`task/delete`, request)
      .then((response) => {
        if (response.data) {
          commit("REMOVE_TASK", request.ids);
          $toast.open({
            message: 'Task Deleted',
            type: 'warning',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTaskCount');
          return true;
        }
        return false;
      });
  },
  async editTask({ commit }, patchModel) {
    return await api.patch(`task`, patchModel[0], patchModel[1])
      .then((response) => {
        if (response) {
          commit("REMOVE_TASK", [response.data.id]);
          commit("ADD_TASK", response.data);
          $toast.open({
            message: 'Task Updated',
            type: 'warning',
            duration: 3000,
            dismissible: true
          })
          return true;
        }
        return false;
      });
  }
}
export const mutations = {

  SET_TASKS(s, tasks) {
    s.tasks = tasks;
    s.isLoading = false;
  },
  ADD_TASK(s, task) {
    s.tasks.items.push(task)
  },
  SET_COUNT(s, response) {
    s.taskCounts = response
  },
  REMOVE_TASK(s, ids) {
    s.tasks.items = s.tasks.items.filter(item => !ids.includes(item.id));
  },
  // UPDATE_TASK(s, response) {
  //     response.forEach(response => {

  //         const task = s.tasks.items.find(task => task.id === response.id);
  //         if (task) {
  //             task.isImportant = response.isImportant;
  //             task.status = response.status
  //             task.dueDate = response.dueDate;
  //             task.content = response.content
  //             task.title = response.title
  //         }
  //     });
  // },
  UPDATE_TASK(s, response) {
    response.forEach(response => {
      const task = s.tasks.items.find(task => task.id === response.id);
      if (task) {
        task.isImportant = response.isImportant;
        task.status = response.status
        task.dueDate = response.dueDate;
        task.content = response.content
        task.title = response.title
      }
    });
  }
}
export default {
  state,
  getters,
  actions,
  mutations
};