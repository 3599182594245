export default {
  install: (app, options) => {
    const store = options.store;
    app.config.globalProperties.$apiCaller = (func, successMessage, panelId, setterName) => {
      if (!panelId) {
        panelId = 'page';
      }
      store.dispatch('loading/setLoading', { id: panelId, loading: true }, { root: true });
      return func.then((result) => {
        if (setterName) {
          store.commit(setterName, result);
        }
        store.dispatch('loading/setLoading', { id: panelId, loading: false }, { root: true });
        if (successMessage) {
          store.dispatch('messages/addMessage', { message: successMessage, status: 'Success' }, { root: true });
        }
        return result;
      }).catch((err) => {
        store.dispatch('messages/addError', err, { root: true });
        store.dispatch('loading/setLoading', { id: panelId, loading: false }, { root: true });
        return false;
      });
    }
    app.provide('apiCaller', app.config.globalProperties.$apiCaller)
  }
}