import api from '@/lib/services/general.service'

export const state = {
  isAuthenticated: false,
  currentUser: null,
  userId: null,
  profilePhoto: null,
};

export const getters = {
  user: s => s.currentUser.profile,
  isAuthenticated: s => s.isAuthenticated,
  access_token: s => s.currentUser?.access_token,
  id_token: s => s.currentUser?.id_token,
  profilePhoto: s => s.profilePhoto,
};

export const actions = {
  async fetchUser({ commit, dispatch }, user) {
    commit('SET_LOGGED_IN', user !== null);
    if (user) {
      commit('SET_USER', user);
      dispatch('updateProfilePhoto');

    } else {
      commit('SET_USER', null);
      commit('UPDATE_PROFILE_PHOTO', null);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async changePassword({ state }, request) {
    const model = {
      password: request.password,
      confirmPassword: request.confirmPassword,
    };
    return await api.insert('user/changepassword', model).then(
      async response => {
        if (response) {
          return response;
        }
      });
  },
  updateProfilePhoto({ commit, state }) {
    const photoUrl = `${process.env.VUE_APP_IMAGE_CDN}/img/user/${state.userId}.jpg?${Date.now()}`;
    commit('UPDATE_PROFILE_PHOTO', photoUrl);
  },
};

export const mutations = {
  SET_LOGGED_IN(state, value) {
    state.isAuthenticated = value;
  },
  SET_USER(state, info) {
    state.currentUser = info;
    if (info) {
      // state.userInfo = info.userInfo;
      state.userId = info.profile.sub;
      // state.role = info.userInfo;
    }
  },
  UPDATE_PROFILE_PHOTO(state, photo) {
    state.profilePhoto = photo;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
