const initialState = () => ({
  entries: [],
  path: [],
  isLoading: false,
})

export const state = initialState()

export const getters = {
  entries: x => x.entries,
  path: x => x.path,
  isLoading: x => x.isLoading
}

export const actions = {
  fetchEntries({ commit, dispatch }, directoryHandle) {
    // commit('RESET_STATE')
    dispatch('readDirectory', directoryHandle)
  },
  async readDirectory({ commit }, directoryHandle) {
    try {
      let items = []

      commit("PUSH_PATH", directoryHandle)

      // Loop through directory entries (both files and folders)
      for await (const [name, handle] of directoryHandle.entries()) {
        if (handle.kind === 'file') {
          // Handle files: Get file properties
          const file = await handle.getFile()
          items.push({
            name: file.name,
            kind: 'File',
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            text: file.text,
          });
        } else if (handle.kind === 'directory') {
          // Handle folders
          items.push({
            name: name,
            kind: 'Folder',
            handle: handle
          })
        }
      }
      
      items.sort((a, b) => {
        if (a.kind === 'Folder' && b.kind === 'File') {
          return -1; // Folder before File
        } else if (a.kind === 'File' && b.kind === 'Folder') {
          return 1; // File after Folder
        } else {
          return 0; // No change if both are the same kind
        }
      })

      commit("SET_ENTRIES", items)
    } catch (err) {
      console.error('Error accessing directory:', err)
    }
  },
  goBack({ commit, state, dispatch }) {
    commit("POP_PATH")
    let directoryHandle = state.path[state.path.length - 1]
    commit("POP_PATH")
    dispatch('readDirectory', directoryHandle)
  }
}

export const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  SET_ENTRIES(x, entries) {
    x.isLoading = false
    x.entries = entries
  },
  PUSH_PATH(x, dir) {
    x.path.push(dir)
    console.table(x.path)
  },
  POP_PATH(x) {
    x.path.pop()
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}