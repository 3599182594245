<template>
  <RouterView />
</template>


<script setup>
</script>

<style scoped>
</style>
